import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const searchParams = {
  namespaced: true,
  state() {
    return {
      condition: null
    }
  },
  getters: {
    condition(state) {
      return state.condition
    }
  },
  mutations: {
    searchParams(state, data) {
      state.condition = data
    }
  },
  actions: {
    searchParamsAsync({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('searchParams', payload)
        resolve()
      })
    }
  }
}
const store = new Vuex.Store({
  modules: {
    preOrderList: searchParams,
    preProductList: searchParams,
    reserveOrderList: searchParams,
    reserveProductList: searchParams,
    shopOrderList: searchParams,
    houseMoneyList: searchParams,
    userList: searchParams,
    notificationList: searchParams,
    mailMagazineList: searchParams,
    informationList: searchParams,
    couponList: searchParams,
    stampCouponList: searchParams,
    shopList: searchParams,
    staffList: searchParams,
    qaInformationList: searchParams,
    contactList: searchParams,
    companyList: searchParams,
    billingStatementList: searchParams,
  }
})
export default store
